import logo from './logo.svg';
import logofdteusc from './image/logo.png'
import './App.css';
import React,{Component,useState} from 'react'
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {InputNumber} from  'primereact/inputnumber';
import {Button} from  'primereact/button';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import fileDownload from 'js-file-download'
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";  
export default class App extends Component{
    constructor(props){
        super(props);
        this.state={
            ci:0,    
            consulta:[]        
        }
        this.ci=0;
        this.onBuscar=this.onBuscar.bind(this);
        this.bodyButton=this.bodyButton.bind(this);
        this.fetchFile=this.fetchFile.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }
    onBuscar(e){
        axios.get("https://certificadoenlinea.net/respuesta.php?ci="+this.ci)
        .then(res => {
          console.log(res.data);
          this.setState({ consulta:res.data });
        })
    }
    
    fetchFile(){
        axios({
              url: `${this.state.consulta[0].enlace}`,
              method: "GET",
              headers: {
                'Content-Type': 'application/pdf',
              },
              responseType: "blob" // important
          })
          fetch(`${this.state.consulta[0].enlace}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/pdf',
                },
            }).then((response) => response.blob())
            .then((blob) => {
            // Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                  "download",
                  `reconocimiento_${this.ci}.pdf`
              );
              document.body.appendChild(link);
              link.click();
      
              // Clean up and remove the link
              link.parentNode.removeChild(link);
          });
          
      }
    
    handleClick(filename){
        this.toast.show({severity:'success', summary: 'Descarga iniciada', detail:'Espere unos minutos', life: 3000});
        window.open(this.state.consulta[0].enlace, "_blank");
        
        /*axios.get(this.state.consulta[0].enlace, {
          responseType: 'blob',
        })
        .then((res) => {
            
            fileDownload(res.data, filename);
        })*/
    }
    bodyButton(rowData,options){
        return (
     
     
             <Button onClick={() => this.handleClick('reconocimiento.pdf')} label="DESCARGAR RECONOCIMIENTO" className="p-button-success" />
     
        );
    }
    render() {
        
        return (
      
            <div className="App">
                <header className="App-header">              
                    <img src={logofdteusc} className="App-logo" alt="logo" />            
                </header>
                <div>
                    <h1>RECONOCIMIENTO DIA DEL MAESTRO 6 DE JUNIO 2024</h1>
                    <br></br>
                    <h3>INGRESE SU NUMERO DE CELULA DE IDENTIDAD</h3>
                    <div className="grid p-fluid">
                        <div className="col-12 md:col-4">                            
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="p-inputgroup">
                                <InputNumber inputId="integeronly" value={this.ci} onValueChange={(e) => this.ci =e.target.value} mode="decimal" useGrouping={false}  />
                                <Button icon="pi pi-search" className="p-button-warning" onClick={this.onBuscar}/>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">                            
                        </div>
                    </div>
                    <Toast ref={(el) => this.toast = el} />
                    {this.state.consulta.length > 0 &&
                    <Fieldset legend="RESUTADO">  
                        
                        <Card >
                            <DataTable value={this.state.consulta} responsiveLayout="stack" breakpoint="960px">                                
                                <Column field="carnet" header="Carnet" />
                                <Column field="nombre" header="Nombre" />                     
                                <Column field="enlace" header="Enlace" body={this.bodyButton}/>                     
                            </DataTable>
                        </Card>
                    
                    </Fieldset>
                    }
                </div>
            </div>
        );
    }
}
//export default App;
